import { Container } from '@mui/system';
import api_url from '../config';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { useMemo } from 'react';

function MainPage({token, setToken, logOut, setTasks, setMistakes}) {
    const [stats, setStats] = useState(null);

    useEffect(() => {
        if(token && !stats){
            async function fetchData() {
                try {
                    const response = await axios.post(`${api_url}/get-stats`, {'auth': token});
                    setStats(response.data);
                } catch(e) {
                    alert(`Something went wrong: ${e}`)
                    logOut(setToken);
                }
            }
            fetchData();
        }
    }, [token, setToken, stats, setStats, logOut]);

    const retrieveTasks = useMemo(() => training => {
        async function fetchData() {
            try {
                const response = await axios.post(`${api_url}/get-tasks`, {'auth': token, 'training': training});

                if(response.data.length === 0) alert("Finished images!");
                else setTasks(response.data);
            } catch(e) {
                alert(`Something went wrong: ${e}`)
                logOut(setToken);
            }
        }
        fetchData();
    }, [token, setToken, setTasks, logOut]);

    const retrieveMistakes = useMemo(() => training => {
        async function fetchData() {
            try {
                const response = await axios.post(`${api_url}/get-mistakes`, {'auth': token, 'training': training});
                setMistakes(response.data);
            } catch(e) {
                alert(`Something went wrong: ${e}`)
                logOut(setToken);
            }
        }
        fetchData();
    }, [token, setToken, setMistakes, logOut]);

    return <React.Fragment>
            <Container className='section' maxWidth="md">
                <Typography variant="h6">
                    Training section ({stats ? stats.training.tot :  '...'} images labelled)
                </Typography>
                Here you can try labelling images and get a score for every image labelled, as well as immediate feedback on your labelling. The results of these labellings will not influence your pilot data. You can label up to 903 images for training.<br /><br />We recommend reaching a score of 45/100 to begin the pilot.<br />You average score over the past 100 images is <b>{stats ? stats.training.avg :  '...'}/100</b>.<br /><br />
                <Button variant='contained' color="primary" sx={{marginRight: '10px'}} onClick={() => retrieveTasks(true)}>Start labelling</Button>
                <Button variant='outlined' color="primary" onClick={() => retrieveMistakes(true)}>Review mistakes</Button>
            </Container>

            <Container className='section' maxWidth="md">
                <Typography variant="h6">
                    Pilot section ({stats ? stats.pilot.tot :  '...'} images labelled)
                </Typography>
                Here you can label the images for the pilot. There are {stats ? stats.pilot.needed : '...'} images left to complete the pilot.<br /><br />You will get a score for every image labelled, as well as immediate feedback on your labelling.<br />Your overall pilot average score is <b>{stats ? stats.pilot.avg :  '...'}/100</b>.<br /><br />
                <Button variant='contained' color="primary" sx={{marginRight: '10px'}} onClick={() => retrieveTasks(false)}>Start labelling</Button>
                <Button variant='outlined' color="primary" onClick={() => retrieveMistakes(false)}>Review mistakes</Button>
            </Container>
        </React.Fragment>;
}

export default MainPage;
