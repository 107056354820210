import { Button, Container } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import ImageZoom from "./ImageZoom";


function MistakesPage({mistakes}) {
    const [selection, setSelection] = useState([]);

    return <div>
        <Container sx={{height: 400}} className='section' maxWidth="md">
            <DataGrid
                rows={mistakes['stats']}
                columns={[
                    { field: 'name', headerName: 'Label name', width: 550 },
                    { field: 'count', headerName: 'Mistakes', width: 100 },
                ]}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                onSelectionModelChange={setSelection}
            />
        </Container>
        {mistakes['mistakes'].filter(mistake => {
            let correctHasLabel = mistake.correct.some(label => selection.includes(label['label']));
            let guessedHasLabel = mistake.labelling['Foods and issues'].some(label => selection.includes(label));
            return correctHasLabel || guessedHasLabel;
        }).map(mistake => <Mistake key={mistake.id} mistake={mistake} />)}
    </div>

}

function Mistake({mistake}) {
    const [show, setShow] = useState(true);

    return show && <Container className='section' maxWidth="md">
        <div className="mistakeSection">
            <ImageZoom current={mistake} />
            <br />
            <p>You selected <b>'{mistake.labelling['Foods and issues'].join()}'</b> with percentages <b>'{mistake.labelling['Percentages'].join()}'</b>.</p>
            <p>The correct labels are <b>'{mistake.correct.map(label => label['label']).join()}'</b> with percentages <b>'{mistake.correct.map(label => label['percentage']).join()}'</b>.</p>
            <p>Score is <b>{mistake.score}</b>/100.</p>
            <Button variant='contained' onClick={() => setShow(false)}>Hide</Button>
        </div>
    </Container>
}

export default MistakesPage;
