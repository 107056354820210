import { Button, TextField } from '@mui/material';
import axios from 'axios';
import api_url from '../config';
import { useEffect, useState } from 'react';

function Login({authenticate}) {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [submit, setSubmit] = useState(false);

    useEffect(() => {
        async function fetchData() {
            if (submit) {
                try {
                    const response = await axios.post(`${api_url}/authenticate`, {
                        'email': email,
                        'password': password
                    });

                    authenticate(response.data);
                } catch(e) {
                    alert(`Something went wrong: ${e}`)

                    setSubmit(false);
                }
            }
        }
        fetchData();
    }, [email, password, submit, authenticate]);

    return <div className='login'>
        <h1>Log In</h1>
        <TextField sx={{marginBottom: '10px'}} disabled={submit} onChange={e => setEmail(e.currentTarget.value)} id="outlined-basic" label="Email" variant="outlined" />
        <TextField type='password' sx={{marginBottom: '20px'}}  disabled={submit} onChange={e => setPassword(e.currentTarget.value)} id="outlined-basic" label="Password" variant="outlined" />
        <Button disabled={submit} variant="contained" onClick={() => setSubmit(true)}>Log In</Button>
    </div>;
}

export default Login;
