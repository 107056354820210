import React, { useEffect, useRef, useState } from 'react';

const ZOOM_MAX = 50;
const ZOOM_MIN = 10;
const ZOOM_DELTA = 1;

function ImageZoom({current}) {
    const [previous, setPrevious] = useState(false);
    const [imageStyle, setImageStyle] = useState({});
    const [zoom, setZoom] = useState(ZOOM_MIN);
    const image = useRef(null);

    useEffect(() => {
        if(image){
            const onWheel = e => {
                e.preventDefault();
                if(e.deltaY < 0) {
                    setZoom(currZoom => {
                        if(currZoom <= ZOOM_MAX - ZOOM_DELTA) {
                            return currZoom + ZOOM_DELTA;
                        }

                        return currZoom;
                    })
                } else {
                    setZoom(currZoom => {
                        if(currZoom >= ZOOM_MIN + ZOOM_DELTA) {
                            return currZoom - ZOOM_DELTA;
                        }

                        return currZoom;
                    })
                }

                setImageStyle(originalStyle => ({
                    transform: `scale(${zoom / 10})`,
                    transformOrigin: originalStyle.transformOrigin
                }))
            };
            const current = image.current;
            current.addEventListener("wheel", onWheel, {passive: false});
            return () => current?.removeEventListener("wheel", onWheel, {passive: false});
        }
    }, [image, zoom, setZoom, setImageStyle]);

    return <div>
        <div className='imageContainer'>
            <img
                alt={'Zoomed example'}
                ref={image}
                style={imageStyle}
                onClick={() => setPrevious(state => !state)}
                onMouseMove={event => {
                    var m_posx = 0, m_posy = 0, e_posx = 0, e_posy = 0, obj = event.currentTarget;
                    //get mouse position on document crossbrowser
                    if (event.pageX || event.pageY){
                        m_posx = event.pageX;
                        m_posy = event.pageY;
                    } else if (event.clientX || event.clientY){
                        m_posx = event.clientX + document.body.scrollLeft
                                + document.documentElement.scrollLeft;
                        m_posy = event.clientY + document.body.scrollTop
                                + document.documentElement.scrollTop;
                    }
                    //get parent element position in document
                    if (obj.offsetParent){
                        do {
                            e_posx += obj.offsetLeft;
                            e_posy += obj.offsetTop;
                        } while (obj = obj.offsetParent);
                    }
                    // mouse position minus elm position is mouseposition relative to element:
                    const x = Math.trunc(((m_posx-e_posx)) / event.target.width * 100);
                    const y = Math.trunc((m_posy-e_posy) / event.target.height * 100);

                    setImageStyle({
                        transform: `scale(${zoom / 10})`,
                        transformOrigin: `${x}% ${y}%`
                    })
                }}
                onMouseLeave={() => {
                    setImageStyle({
                        transform: 'scale(1)'
                    })
                }}
                className='labelImage' src={`https://labeling-platform.s3.eu-central-1.amazonaws.com/${previous ? current.prev_image_id : current.image_id}`}
            />
        </div>
        [ID: {current.id}] Scale measured {current.weight} grams, {previous ? 'before' : 'after'} image
    </div>;
}

export default ImageZoom;