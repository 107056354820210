import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, TextField, Typography } from '@mui/material';
import api_url from '../config';
import axios from 'axios';
import data from '../labels';
import _ from 'underscore';
import ImageZoom from './ImageZoom';

const getSubLabels = (source) => {
    const iterateToKeys = (keys, object) => {
        Object.values(object).forEach(value => {
            if(typeof(value) !== 'string'){
                iterateToKeys(keys, value);
            } else {
                keys.push(value);
            }
        });
    };

    const subLabels = [];
    if(typeof(source) !== 'string'){
        iterateToKeys(subLabels, source);
    } else {
        subLabels.push(source);
    }

    return subLabels;
}

const options = [
    ...Object.keys(data['Issue']).map(label => ({'name': label, 'group': "Issues"})),
    ...getSubLabels(data['Food']).map(label => ({'name': label, 'group': "Foods"}))
]

function LabellingPage({token, setToken, logOut, tasks, goHome}) {
    const [current, setCurrent] = useState(_.sample(tasks, 1));
    const [labelled, setLabelled] = useState([]);
    const [submit, setSubmit] = useState(false);
    const [labels, setLabels] = useState([{'label': null, 'percentage': 0}]);

    useEffect(() => {
        const next = _.sample(tasks.filter(task => !labelled.includes(task)), 1);
        if(next.length){
            setCurrent(next[0]);
        } else {
            alert('Tasks finished!');
            goHome();
        }
    }, [labelled, setCurrent, tasks, goHome]);

    useEffect(() => {
        setLabels([{'label': null, 'percentage': 0}]);
    }, [current, setLabels]);

    useEffect(() => {
        if(token && submit){
            async function fetchData() {
                try {
                    const response = await axios.post(`${api_url}/label-task`, {
                        'auth': token,
                        'id': current.id,
                        'labels': labels.reduce((acc, val) => `${acc},${val.label?.name}`, ''),
                        'percentages': labels.reduce((acc, val) => `${acc},${val.percentage}`, '')
                    });

                    if(response.data.message){
                        alert(`Your score is 0/100.\n${response.data.message}.\n\nTry again!`)
                    } else {
                        const score = response.data.score;
                        const correctLabels = response.data.correct_labels;
                        const correctPercentage = response.data.correct_percentages;
                        alert(`Your score is ${score}/100.\nThe correct labels were: ${correctLabels}.\nThe correct percentages were: ${correctPercentage}.`)
                        setLabelled(previousLabelled => {
                            const newLabelled = [...previousLabelled];
                            newLabelled.push(current);
                            return newLabelled;
                        })
                    }

                    setSubmit(false);
                } catch(e) {
                    alert(`Something went wrong: ${e}`)
                    logOut(setToken);
                }
            }
            fetchData();
        }
    }, [token, setToken, submit, logOut, labels, current, setLabelled]);

    return <React.Fragment>
        <Container className='section labellingSection' maxWidth="md">
            {current && <ImageZoom current={current} />}

            <Typography style={{marginTop: '20px', marginBottom: '10px'}} variant="h6" >
                Labelling
            </Typography>

            {labels.map((label, index) => <Label
                key={index}
                label={label['label']}
                percentage={label['percentage']}
                onLabelChange={(event, newLabel) => setLabels(previousLabels => {
                    const newLabels = JSON.parse(JSON.stringify(previousLabels));
                    newLabels[index].label = newLabel;
                    return newLabels;
                })}
                onPercentageChange={event => setLabels(previousLabels => {
                    const newLabels = JSON.parse(JSON.stringify(previousLabels));
                    newLabels[index].percentage = event.target.value;
                    return newLabels;
                })}
                remove={() => setLabels(previousLabels => {
                    const newLabels = JSON.parse(JSON.stringify(previousLabels));
                    newLabels.splice(index, 1);
                    return newLabels;
                })}
                error={
                    label['percentage'] < 0
                    || label['percentage'] > 100
                    || labels.reduce((acc, label) => (acc + parseInt(label['percentage'])), 0) > 100
                }
                helperText={
                    (label['percentage'] < 0 || label['percentage'] > 100)
                    ? 'Percentage must be between 0 and 100'
                    : (
                        labels.reduce((acc, label) => (acc + parseInt(label['percentage'])), 0) > 100
                        ? 'Total percentage is above 100'
                        : null
                    )
                }
            />)}
            <Button
                style={{marginTop: '10px'}}
                variant='contained'
                color="primary"
                onClick={() => setLabels(previousLabels => {
                    const newLabels = JSON.parse(JSON.stringify(previousLabels));
                    newLabels.push({'label': null, 'percentage': 0});
                    return newLabels;
                })}>+</Button>

            <Button style={{marginTop: '25px'}} variant='contained' color="primary" onClick={() => setSubmit(true)}>Save and next</Button>
        </Container>
    </React.Fragment>;
}

function Label({label, percentage, onLabelChange, onPercentageChange, remove, error, helperText}) {
    console.log(label);
    return <div className='option'>
        <Autocomplete
            options={options}
            groupBy={(option) => option.group}
            getOptionLabel={(option) => option.name}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Label" />}
            value={label}
            onChange={onLabelChange}
            isOptionEqualToValue={(a, b) => JSON.stringify(a) === JSON.stringify(b)}
        />
        <TextField sx={{width: 150, marginLeft: '10px'}} label='Percentage' type="number" value={percentage} onChange={onPercentageChange} helperText={helperText} error={error}/>
        <Button style={{marginLeft: '10px'}} variant='outlined' color="primary" size='small' onClick={remove}>x</Button>
    </div>
}

export default LabellingPage;
