import './App.css';
import { useState } from 'react';
import Login from './components/login';
import MainPage from './components/MainPage';
import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material';
import LabellingPage from './components/LabellingPage';
import { LaunchSharp } from '@mui/icons-material';
import MistakesPage from './components/MistakesPage';

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

const authenticate = setToken => value => {
    localStorage.setItem('authToken', value);
    setToken(localStorage.authToken);
}

const logOut = setToken => {
    localStorage.removeItem('authToken');
    setToken(localStorage.authToken);
}

function App() {
    const [token, setToken] = useState(localStorage.authToken);
    const [tasks, setTasks] = useState(null);
    const [mistakes, setMistakes] = useState(null);

    return !token
        ? <Login authenticate={authenticate(setToken)}/>
        :
        <div className='main'>
            <NavBar token={token} setToken={setToken} setTasks={setTasks} setMistakes={setMistakes} />

            {
              tasks
              ? <LabellingPage token={token} setToken={setToken} logOut={logOut} tasks={tasks} goHome={() => setTasks(null)} />
              : mistakes
                ? <MistakesPage mistakes={mistakes} />
                : <MainPage token={token} setToken={setToken} setTasks={setTasks} setMistakes={setMistakes} logOut={logOut} />
            }

            <div className='footer'>
              Any questions? Reach out at &nbsp;<u>davide.halili@kitro.ch</u>
            </div>
        </div>;
}

function NavBar({token, setToken, setMistakes, setTasks}) {
    return <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Piloting Platform ({token && parseJwt(token).email})
          </Typography>
          <Button startIcon={<LaunchSharp />} color="inherit" onClick={() => window.open('https://labelpicker.kitro.ch', '_blank', 'noreferrer')}>Labels</Button>
          <Button color="inherit" onClick={() => {
            setTasks(null);
            setMistakes(null);
          }}>Home</Button>
          <Button color="inherit" onClick={() => logOut(setToken)}>Logout</Button>
        </Toolbar>
      </AppBar>
    </Box>;
}

export default App;
